import React, { Component } from 'react';

// const today = new Date('Sat Aug 24 2024 00:00:00 GMT+0530 (India Standard Time)');
const today = new Date();

class SaturdaySet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: today.getFullYear(),
      month: today.getMonth(),
      isSpecialSaturday: false
    };
  }

  componentDidMount() {
    this.checkIfTodayIsSpecialSaturday();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.year !== this.state.year || prevState.month !== this.state.month) {
      this.checkIfTodayIsSpecialSaturday();
    }
  }

  checkIfTodayIsSpecialSaturday = () => {
    const { year, month } = this.state;

    // Calculate the 2nd and 4th Saturdays of the month
    const saturdays = this.calculateSaturdays(year, month);

    // Check if today is one of those Saturdays
    const isSpecialSaturday = saturdays.some(
      (saturday) =>
        saturday.getDate() === today.getDate() &&
        saturday.getMonth() === today.getMonth() &&
        saturday.getFullYear() === today.getFullYear()
    );

    // Update the state with the result
    this.setState({ isSpecialSaturday }, () => {
      this.props.onSaturdaysUpdate(isSpecialSaturday);
    });
  };

  calculateSaturdays = (year, month) => {
    const secondAndFourthSaturdays = [];
    const date = new Date(year, month, 1);

    // Find the first Saturday of the month
    while (date.getDay() !== 6) {
      date.setDate(date.getDate() + 1);
    }

    let saturdayCount = 1;

    while (date.getMonth() === month) {
      if (saturdayCount === 2 || saturdayCount === 4) {
        secondAndFourthSaturdays.push(new Date(date));
      }

      date.setDate(date.getDate() + 7);
      saturdayCount++;
    }

    return secondAndFourthSaturdays;
  };

  render() {
    return (
      <div className='d-none'>
      </div>
    );
  }
}

export default SaturdaySet;
